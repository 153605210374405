:root {
  --primary-1: #2a85ff;
  --primary-2: #83bf6e;
  --primary-3: #ff6a55;
  --primary-4: #8e59ff;
  --primary-5: #f7ab17;

  --secondary-1: #ffbc99;
  --secondary-2: #cabdff;
  --secondary-3: #b1e5fc;
  --secondary-4: #b5e4ca;
  --secondary-5: #ffd88d;

  --neutral-0: #ffffff;
  --neutral-1: #fcfcfc;
  --neutral-2: #f4f4f4;
  --neutral-3: #efefef;
  --neutral-4: #6f767e;
  --neutral-5: #33383f;
  --neutral-6: #272b30;
  --neutral-7: #1a1d1f;
  --neutral-8: #111315;

  --shades--Neutral-Shades-04-75: #9a9fa5;
  --shades--Neutral-Shades-dark-04-50: #6f767e;
  --shades--Neutral-Shades-dark-04-40: #6f767e66;
  --shades--Neutral-Shades-dark-08_over-07-50: #11131580;

  --action-Action-Active: #707070;
  --action-Action-Resting: #f1f1f1;
  --action-Action-Pressed: #4b4b4b;
  --action-Action-Disabled: #878787;
  --action-Action-Disabled_background: #dfdfdf;
  --action-Action-Hover: #e9e9e9;

  --other-Outlined-border-stroke: #bfbfbf4d;

  --default-spacing: 8px;
}

.apexcharts-tooltip {
  background: transparent !important;
  border: none !important;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.line-clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
